import { DateTime } from 'luxon'
import parse from './parse'

export default {
  toMDTG: function(datetime) {
    let dt = parse.asDateTime(datetime)
    if (!dt.isValid) {
      return ''
    }

    dt = dt.setZone('utc')
    return dt.toFormat('dd\' \'HHmm\'Z\'\' \'MMM\' \'yy').toUpperCase()
  },
  toLocal: function(datetime, format) {
    const dt = parse.asDateTime(datetime)

    if (!dt.isValid) {
      return ''
    }

    format = format || Object.assign(DateTime.DATETIME_MED, DateTime.TIME_24_SIMPLE)
    return dt.toLocaleString(format)
  },
  toTodaysTimeOrPastDate: function(datetime) {
    const dt = parse.asDateTime(datetime)

    if (!dt.isValid) {
      return ''
    }

    if(dt.hasSame(DateTime.local(), 'day')) {
      return dt.toFormat('HH:mm')
    }

    if(dt.hasSame(DateTime.local(), 'year')) {
      return dt.toLocaleString(DateTime.DATE_MED)
    }

    return dt.toLocaleString(Object.assign(DateTime.DATETIME_MED, DateTime.TIME_24_SIMPLE))
  }
}
